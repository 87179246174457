




















import { Component, Watch } from "vue-property-decorator";

import Dish from "../components/Dish.vue";
import Sidebar from "../components/Sidebar.vue";
import SidebarMobile from "../components/SidebarMobile.vue";
import BasketSnackbar from "../components/Snackbar.vue";

import { IDishGroup } from "../@types/dish.type";
import { dishesHardcodedList } from "../utils/dishesList";
import { BasketModule } from "../store/basket.module";
import { CitiesModule } from "../store/cities.module";
import SidebarMixin from "../mixins/sidebar.mixin";
import { WithLoadingAnimation } from "@/utils/decorators";

@Component({ components: { Dish, Sidebar, SidebarMobile, BasketSnackbar } })
export default class ProductGroupsIndexView extends SidebarMixin {
  // productGroups: ProductGroups[] = [];

  get isMobile() {
    return this.$vuetify.breakpoint.xs;
  }

  get dishes(): IDishGroup[] {
    return dishesHardcodedList;
  }

  get productGroups() {
    return BasketModule.productGroups;
  }

  get selectedProducts() {
    return BasketModule.selectedProducts;
  }

  $refs: {
    basketSnackbarRef: BasketSnackbar;
  };

  @Watch("selectedProducts", { deep: true })
  onDishesChanged() {
    let overallPrice = 0;
    let selectedProductsCount = 0;

    this.selectedProducts.map(product => {
      // computing the overall count
      selectedProductsCount += product.overallUserSelectionCount;
      // computing the overall cost
      for (const selection of Object.values(product.prices)) {
        if (selection.userSelectionCount >= 0) overallPrice += selection.price * selection.userSelectionCount;
      }
    });

    BasketModule.setOverallPrice(overallPrice);
    BasketModule.setDishesCount(selectedProductsCount);
    if (selectedProductsCount === 0) return;
    this.$refs.basketSnackbarRef.showBasketSnackbar();
  }

  @WithLoadingAnimation
  async mounted() {
    await BasketModule.getProductsList();
    await CitiesModule.getCities();
  }
}
