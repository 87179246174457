








import { Component } from "vue-property-decorator";
import SidebarMixin from "../mixins/sidebar.mixin";

@Component
export default class SidebarMobile extends SidebarMixin {}
