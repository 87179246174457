












import { ScrollModule } from "@/modules/base/store/scroll.module";
import { Component } from "vue-property-decorator";
import SidebarMixin from "../mixins/sidebar.mixin";

@Component
export default class extends SidebarMixin {
  get isVisible() {
    return ScrollModule.isDesktopSidebarVisible;
  }
}
