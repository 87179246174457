





































































import { Component, Prop, Vue } from "vue-property-decorator";
import ReadMore from "@/components/ui/ReadMore.vue";
import { Product } from "../@types/product.type";
import { BasketModule } from "../store/basket.module";

@Component({ components: { ReadMore } })
export default class extends Vue {
  @Prop()
  private product: Product;

  // VUEX DATA
  get expandedItems() {
    return BasketModule.expandedItems;
  }
  get isExpanded() {
    return this.expandedItems.includes(this.product.id);
  }
  set isExpanded(_val) {
    if (this.expandedItems.includes(this.product.id)) BasketModule.clearExpandedItems();
    else BasketModule.setExpandedItems(this.product.id);
  }

  get defaultPrice() {
    if (this.product.prices.length) {
      const price = this.product.prices.find(price => price.is_default === 1);
      // if there's default price
      if (price && price.id) return price.price;
      // otherwise return 1st as default
      else return this.product.prices[0].price;
    }
    return null;
  }

  // FUNCTIONAL
  onIncrementCount(selectionIndex: number) {
    this.product.overallUserSelectionCount++;
    this.product.prices[selectionIndex].userSelectionCount++;
  }
  onDecrmentCount(selectionIndex: number) {
    if (this.product.prices[selectionIndex].userSelectionCount === 0) return;
    this.product.overallUserSelectionCount--;
    this.product.prices[selectionIndex].userSelectionCount--;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xs;
  }
}
