















import { Component, Vue } from "vue-property-decorator";
import { BasketModule } from "../store/basket.module";

import Basket from "./Basket.vue";

@Component({ components: { Basket } })
export default class BasketSnackbar extends Vue {
  multiLine = true;
  snackbar = false;
  text = `I'm a multi-line snackbar.`;

  $refs: {
    basketRef: Basket;
  };

  get overallPrice() {
    return BasketModule.overallPrice;
  }

  get dishesCount() {
    return BasketModule.dishesCount;
  }

  get postfix() {
    if (this.dishesCount === 1) return "товар";
    if (this.dishesCount > 1 && this.dishesCount <= 4) return "товара";
    else return "товаров";
  }

  showBasketSnackbar() {
    this.snackbar = true;
  }

  onOpenBasketClicked() {
    this.snackbar = false;
    this.$refs.basketRef.show();
  }
}
